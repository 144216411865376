import React, {  Suspense,useState, useEffect } from 'react'
import { HashRouter, Navigate, Route, Routes } from 'react-router-dom'
import { useAuth } from './views/pages/auth/AuthContext'
import './scss/style.scss'
import './assets/style/global.css'
import API from './server/apiRouter'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Unpaid = React.lazy(() => import('./views/pages/unpaid/Unpaid'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Recover = React.lazy(() => import('./views/pages/recover/Recover'))
const ConfirmCode = React.lazy(() => import('./views/pages/confirmCode/ConfirmCode'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

const App=()=> {
    const { isAuthenticated, setIsAuthenticated } = useAuth()
    const [user,setUser]=useState({})
    
    useEffect(() => {
      const loggedInUser = localStorage.getItem("user")
      try{
        setUser(JSON.parse(loggedInUser))
        setIsAuthenticated(true)
      }catch(e){
        setUser({})
      }
    }, [])

    const logout = () => {
      localStorage.removeItem("user")
      localStorage.removeItem("token")
      localStorage.removeItem("accountType")
      localStorage.removeItem("accountId")
      localStorage.clear()
      setIsAuthenticated(false)
      window.location.reload(false)
    }

    useEffect(()=>{
      const testToken = async () => {
        const headers = new Headers();
        const token = localStorage.getItem('token');
        headers.set('Content-Type', 'application/json');
        headers.set('Authorization', token);
        try {
          const response = await fetch(`${API.allManager}`, {
            method: 'GET',
            headers: headers,
          })
          if (response.status==401 && token) {
            throw new Error('O token expirou')
          }
        } catch (error) {
          console.log(error.message)
          logout()
        }
      }
      testToken()
    },[])

    return (
      <HashRouter>
        <Suspense fallback={loading}>
          <Routes>
            <Route exact path="/login" name="Login Page" element={<Login/>} />
            <Route exact path="/unpaid" name="License Page" element={<Unpaid/>} />
            <Route exact path="/register" name="Register Page" element={<Register />} />
            <Route exact path="/recover" name="Recover Page" element={<Recover />} />
            <Route exact path="/confirmCode" name="Confirm Code" element={<ConfirmCode />} />
            <Route exact path="/404" name="Page 404" element={<Page404 />} />
            <Route exact path="/500" name="Page 500" element={<Page500 />} />
            <Route
              path="*"
              name="Home" 
              element={(isAuthenticated || Boolean(user)) ? <DefaultLayout /> : <Navigate to="/login" />}
            />
          </Routes>
        </Suspense>
      </HashRouter>
    )
}

export default App
