//import {env} from "node:process"

const DOMAIN=process.env.REACT_APP_API_URL
//const DOMAIN="http://localhost:8081"

const API={
    domain:DOMAIN,

    user:`${DOMAIN}/user`,
    allUser:`${DOMAIN}/user/all`,

    manager:`${DOMAIN}/manager`,
    allManager:`${DOMAIN}/manager/all`,
    quantManager:`${DOMAIN}/manager/count`,
    quantAccounts:`${DOMAIN}/manager/count/accounts`,
    managerConfirm:`${DOMAIN}/manager/confirmCode`,
    managerRecovery:`${DOMAIN}/manager/recovery`,
    managerCreateImg:`${DOMAIN}/manager/image/create`,
    managerUpdateImg:`${DOMAIN}/manager/image/update`,

    teacher:`${DOMAIN}/teacher`,
    allTeacher:`${DOMAIN}/teacher/all`,
    allTeacherOfClassRoom:`${DOMAIN}/teacher/all/of/classRoom`,
    quantTeacher:`${DOMAIN}/teacher/count`,
    quantAccounts:`${DOMAIN}/teacher/count/accounts`,
    teacherCreateImg:`${DOMAIN}/teacher/image/create`,
    teacherUpdateImg:`${DOMAIN}/teacher/image/update`,
    teacherAddClassRoom:`${DOMAIN}/teacher/add/classRoom`,
    teacherDelClassRoom:`${DOMAIN}/teacher/del/classRoom`,

    student:`${DOMAIN}/student`,
    allStudent:`${DOMAIN}/student/all`,
    allStudentOfSponsor:`${DOMAIN}/student/all/of/sponsor`,
    allStudentOfClassRoom:`${DOMAIN}/student/all/of/classRoom`,
    allStudentOfCategory:`${DOMAIN}/student/all/of/category`,
    allStudentOfStatusAndCategory:`${DOMAIN}/student/all/of/statusAndCategory`,
    quantStudent:`${DOMAIN}/student/count`,
    quantAccounts:`${DOMAIN}/student/count/accounts`,
    studentCreateImg:`${DOMAIN}/student/image/create`,
    studentUpdateImg:`${DOMAIN}/student/image/update`,
    studentAddSponsor:`${DOMAIN}/student/add/sponsor`,
    studentDelSponsor:`${DOMAIN}/student/del/sponsor`,
    studentAddClassRoom:`${DOMAIN}/student/add/classRoom`,
    studentDelClassRoom:`${DOMAIN}/student/del/classRoom`,

    sponsor:`${DOMAIN}/sponsor`,
    allSponsor:`${DOMAIN}/sponsor/all`,
    allSponsorOfStudent:`${DOMAIN}/sponsor/all/of/student`,
    quantSponsor:`${DOMAIN}/sponsor/count`,
    quantAccounts:`${DOMAIN}/sponsor/count/accounts`,
    sponsorCreateImg:`${DOMAIN}/sponsor/image/create`,
    sponsorUpdateImg:`${DOMAIN}/sponsor/image/update`,
    sponsorAddStudent:`${DOMAIN}/sponsor/add/student`,
    sponsorDelStudent:`${DOMAIN}/sponsor/del/student`,

    client:`${DOMAIN}/api/client`,
    allClient:`${DOMAIN}/api/client/all`,
    quantclient:`${DOMAIN}/api/client/count`,
    clientCreateImg:`${DOMAIN}/api/client/image/create`,
    clientUpdateImg:`${DOMAIN}/api/client/image/update`,
    clientLicense:`${DOMAIN}/api/license/client`,

    category:`${DOMAIN}/category`,
    allCategories:`${DOMAIN}/category/all`,
    quantCategory:`${DOMAIN}/category/count`,
    categoryAddSubjects:`${DOMAIN}/category/add/subjects`,
    
    subject:`${DOMAIN}/subject`,
    allSubjects:`${DOMAIN}/subject/all`,
    allSubjectsOfCategory:`${DOMAIN}/subject/all/of/category`,
    allSubjectsOfClassRoom:`${DOMAIN}/subject/all/of/classRoom`,
    quantSubject:`${DOMAIN}/subject/count`,

    classRoom:`${DOMAIN}/classRoom`,
    allClassRoomOfStudent:`${DOMAIN}/classRoom/all/of/student`,
    allClassRoomOfTeacher:`${DOMAIN}/classRoom/all/of/teacher`,
    allClassRooms:`${DOMAIN}/classRoom/all`,
    quantClassRoom:`${DOMAIN}/classRoom/count`,
    classRoomAddStudent:`${DOMAIN}/classRoom/add/student`,
    classRoomDelStudent:`${DOMAIN}/classRoom/del/student`,
    classRoomAddTeacher:`${DOMAIN}/classRoom/add/teacher`,
    classRoomDelTeacher:`${DOMAIN}/classRoom/del/teacher`,
    classRoomAddSchedule:`${DOMAIN}/classRoom/add/schedule`,
    classRoomDelSchedule:`${DOMAIN}/classRoom/del/schedule`,

    schedule:`${DOMAIN}/schedule`,
    scheduleInUse:`${DOMAIN}/schedule/inUse`,
    allSchedule:`${DOMAIN}/schedule/all`,
    allScheduleOfClassRoom:`${DOMAIN}/schedule/all/of/classRoom`,
    allScheduleOfStudent:`${DOMAIN}/schedule/all/of/student`,
    allScheduleOfTeacher:`${DOMAIN}/schedule/all/of/teacher`,
    scheduleAddSubjects:`${DOMAIN}/schedule/add/subjects`,

    lesson:`${DOMAIN}/lesson`,
    allLesson:`${DOMAIN}/lesson/all`,
    allLessonOfClassRoom:`${DOMAIN}/lesson/all/of/classRoom`,
    allLessonOfStudent:`${DOMAIN}/lesson/all/of/student`,
    allLessonOfTeacher:`${DOMAIN}/lesson/all/of/teacher`,
    allLessonOfSchedule:`${DOMAIN}/lesson/all/of/schedule`,

    observation:`${DOMAIN}/observation`,
    allObservation:`${DOMAIN}/observation/all`,
    allObservationOfStudent:`${DOMAIN}/observation/all/of/student`,
    allObservationOfTeacher:`${DOMAIN}/observation/all/of/teacher`,

    presence:`${DOMAIN}/presence`,
    allPresence:`${DOMAIN}/presence/all`,
    allPresenceByDate:`${DOMAIN}/presence/all/by/date`,
    allPresenceOfStudent:`${DOMAIN}/presence/all/of/student`,
    allPresenceOfTeacher:`${DOMAIN}/presence/all/of/teacher`,

    enrollment:`${DOMAIN}/enrollment`,
    allEnrollment:`${DOMAIN}/enrollment/all`,
    allEnrollmentByDate:`${DOMAIN}/enrollment/all/by/date`,
    allEnrollmentOfStudent:`${DOMAIN}/enrollment/all/of/student`,
    allEnrollmentOfTeacher:`${DOMAIN}/enrollment/all/of/teacher`,
    quantEnrollment:`${DOMAIN}/enrollment/count`,
    enrollmentAddSubjects:`${DOMAIN}/enrollment/add/subjects`,

    monthPay:`${DOMAIN}/monthPay`,
    allMonthPay:`${DOMAIN}/monthPay/all`,
    allMonthPayYears:`${DOMAIN}/monthPay/all/years`,
    allMonthPayByPayYear:`${DOMAIN}/monthPay/all/by/payYear`,
    allMonthPayOfStudent:`${DOMAIN}/monthPay/all/of/student`,
    quantMonthPay:`${DOMAIN}/monthPay/count`,
    monthPayConfirmPay:`${DOMAIN}/monthPay/confirm/payment`,
}

export default API